<template>
  <div id="app">
    <img src="./assets/logo.png" alt="" v-if="!isAuth" />
    <div v-if="isAuth" class="success-panel">
      <svg
        t="1635403793068"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2662"
        width="128"
        height="128"
      >
        <path
          d="M510.545 28.22c-267.043 0-483.521 216.477-483.521 483.52s216.479 483.521 483.521 483.521 483.52-216.479 483.52-483.521S777.588 28.22 510.545 28.22zM776.855 407.855l-315.37 315.37c-9.763 9.763-22.559 14.645-35.355 14.645-12.796 0-25.592-4.882-35.355-14.645l-176.13-176.13c-19.526-19.525-19.526-51.184 0-70.71 19.526-19.526 51.184-19.527 70.711 0L426.13 617.159l280.015-280.015c19.527-19.526 51.184-19.526 70.711 0C796.382 356.671 796.382 388.329 776.855 407.855z"
          p-id="2663"
          fill="#1296db"
        ></path>
      </svg>
      <div class="tips">授权成功</div>
    </div>
    <div class="auth-panel" v-if="!isAuth">
      <div class="title">手机号授权</div>
      <van-form @submit="onSubmit" class="form" style="width: 100%">
        <van-field
          v-model="phone"
          name="手机号"
          label
          type="tel"
          left-icon="phone-o"
          placeholder="输入手机号"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="name"
          name="名字"
          left-icon="user-circle-o"
          placeholder="填写名字"
          :rules="[{ required: true }]"
        />
        <div style="margin: 1rem">
          <van-button
            round
            block
            type="info"
            native-type="submit"
            color="linear-gradient(90deg,#1da57a 0,#aae3d1)"
            :loading="isLoading"
            loading-type="spinner"
            >授 权</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script scoped>
import axios from 'axios';
import qs from 'qs';
import { Toast } from 'vant';

export default {
  name: 'Auth',
  data() {
    return {
      code: '',
      phone: '',
      name: '',
      isAuth: false,
      isLoading: false,
    };
  },
  created() {
    this.isAuth = localStorage.getItem('data') ? true : false;
    this.code = this.getQueryVariable('code') || '';
  },
  mounted() {
    if (this.isAuth) {
      return;
    }
    if (!this.code) {
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7bee4978a4439333&redirect_uri=http://register.pancares.com/login&response_type=code&scope=snsapi_userinfo&state=STATE';
    }
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      const data = qs.stringify({
        code: this.code,
        phone: this.phone,
        name: this.name
      })
      axios
        .post('https://register.pancares.com/api/patient/wx/webRegister', data, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((res) => {
          console.log('res', res)
          if (res.data.code === 10214) {
            Toast({
              message: res.data.msg,
              position: 'top',
            });
          } else if (res.data.code !== 200) {
            // location.reload();
            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7bee4978a4439333&redirect_uri=http://register.pancares.com/login&response_type=code&scope=snsapi_userinfo&state=STATE';
          } else {
            this.isAuth = true;
            localStorage.setItem('data', res.data.data);
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          alert(err);
        });
    },

    getQueryVariable(variable) {
      let query = window.location.search.substring(1);
      let vars = query.split('&');
      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
  },
};
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, #1da57a 0, #fff);
  display: flex;
  justify-content: center;
}

img {
  display: block;
  height: 5.9375rem;
  margin: 2.5rem;
}

svg {
  margin-top: 6.25rem;
}

.success-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success-panel > .tips {
  margin: 0.625rem;
  font-size: 1.625rem;
  letter-spacing: 0.3125rem;
}

.auth-panel {
  width: 18.75rem;
  height: 15.625rem;
  background-color: white;
  position: fixed;
  border-radius: 0.625rem;
  top: 11.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-panel > .title {
  margin-top: 1.25rem;
  font-weight: 600;
  border-bottom: 0.0625rem;
}

.auth-panel > .form {
  margin-top: 1.25rem;
}
</style>
