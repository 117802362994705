import Vue from 'vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import Auth from './Auth.vue';
// import VConsole from 'vconsole';

// new VConsole()

Vue.config.productionTip = false

Vue.use(Vant)

new Vue({
  render: h => h(Auth),
}).$mount('#app')